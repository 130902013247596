export const options = {
  tooltip: {
    trigger: 'axis'
  },
  grid: {
    width: '100%',
    height: 220,
    left: '0%',
    top: '8%',
    containLabel: true
  },
  xAxis: {
    type: 'category',
    boundaryGap: true,
    name: '(日)',
    nameLocation: 'end',
    nameGap: '20',
    nameTextStyle: {
      padding: [24, 0, 0, 0]
    },
    axisLine: {
      lineStyle: {
        color: '#fff'
      }
    },
    axisTick: {
      show: false
    },
    axisPointer: {
      show: true,
      label: {
        show: false
      },
      lineStyle: {
        color: '#2EDBFF'
      }
    },
  },
  yAxis: [{
    type: 'value',
    axisLine: {
      show: false,
      lineStyle: {
        color: '#fff'
      }
    },
    axisTick: {
      show: false
    },
    splitLine: {
      lineStyle: {
        color: '#333',
        type: 'dotted'
      }
    }
  }, {
    type: 'value',
    show: false,
    min: 0,
    max: function (value) {
      return value.max < 100 ? 100 : value.max
    },
    // interval: 20,
    minInterval: 1,
    axisLine: {
      show: false,
      lineStyle: {
        color: '#fff'
      }
    },
    axisTick: {
      show: false
    },
    splitLine: {
      lineStyle: {
        color: '#333',
        type: 'dotted'
      }
    },
    axisLabel: {
      show: true,
      interval: 'auto',
      formatter: '{value}%'
    }
  }],
  legend: {
    type: 'scroll',
    icon: 'path://M64 480h896v64H64z',
    top: '90%',
    inactiveColor: '#666666',
    itemGap: 18,
    textStyle: {
      color: '#ECECEC',
      fontSize: 10
    },
    pageTextStyle: {
      color: '#FFF'
    }
  }
}
