<template>
  <table
    cellspacing="0"
    cellpadding="0"
    border="0"
    :class="[
      'mua-table',
      {
        'mua-table--border': border
      }
    ]"
  >
    <div
      :class="[
        'mua-table__head',
        {
          'mua-table--background': background,
          'mua-table--scroll': scroll
        }
      ]"
    >
      <table cellspacing="0" cellpadding="0" border="0">
        <thead>
          <tr class="mua-tr">
            <th
              :class="[
                'mua-th',
                {
                  'mua-th__index': item.key === '$index'
                }
              ]"
              colspan="1"
              rowspan="1"
              v-for="item in head"
              :key="item.key"
            >
              <span>{{item.title}}</span>
              <slot v-if="item.sort" name="sort"></slot>
            </th>
          </tr>
        </thead>
      </table>
    </div>
    <div class="mua-table__body" ref="mua__tbody">
      <table cellspacing="0" cellpadding="0" border="0">
        <tbody>
          <tr :class="['mua-tr', {'mua-tr__hover': background}]" v-for="(item, index) in data" :key="index" @click="handleClick(item, index)">
            <td
              :class="[
                'mua-td',
                {
                  'mua-td__index': td.key === '$index',
                  'mua-td__sort': td.sort
                }
              ]"
              colspan="1"
              rowspan="1"
              v-for="td in head"
              :key="td.key"
            >
              <span v-if="typeof item[td.dataIndex] !== undefined" :class="td.sort ? (+item[td.dataIndex] > 0 ? 'up' : 'down') : ''">
                {{td.sort ? `${Math.abs(+item[td.dataIndex])}%` : item[td.dataIndex]}}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </table>
</template>

<script>
export default {
  name: 'MuaTable',
  props: {
    background: {
      default: false,
      type: Boolean
    },
    border: {
      default: false,
      type: Boolean
    },
    head: {
      type: Array,
      required: true,
      default: () => {
        return []
      }
    },
    data: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      scroll: false
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.scroll = this.$refs.mua__tbody.scrollHeight > this.$refs.mua__tbody.clientHeight
    })
  },
  methods: {
    handleClick(e, i) {
      this.$emit('click', e, i);
    }
  }
};
</script>

<style scoped>
.mua-table--background {
  background-image: linear-gradient(270deg, #01D18A 0%, #2483FF 100%);
}
.mua-table--scroll {
  padding-right: 0.25rem;
}
.mua-table,
.mua-table__head table,
.mua-table__body table {
  position: relative;
  width: 100%;
  font-size: 0.75rem;
  overflow: hidden;
  box-sizing: border-box;
}
.mua-table__head {
  width: 100%;
  box-sizing: border-box;
}
.mua-table .mua-tr {
  width: 100%;
  display: flex;
}
.mua-table .mua-th,
.mua-table .mua-td {
  flex: 1;
  padding: 4px 0;
  overflow: hidden;
  user-select: none;
  color: #fff;
  text-align: center;
}
.mua-table .mua-th {
  font-size: 0.9375rem;
}
.mua-table--border {
  border: 1px solid #FFF;
}
.mua-table--border .mua-th,
.mua-table--border .mua-td {
  border-right: 1px solid #FFF;
}
.mua-table--border .mua-th:last-child,
.mua-table--border .mua-td:last-child {
  border-right: 0;
}
.mua-table--border .mua-tr {
  border-bottom: 1px solid #FFF;
}
.mua-table--border .mua-table__body .mua-tr:last-child {
  border-bottom: 0;
}
.mua-table--border .mua-th {
  font-size: 0.75rem;
}
.mua-table .mua-tr__hover {
  cursor: pointer;
}
.mua-table .mua-tr__hover:hover {
  background-image: linear-gradient(270deg, #01D18A 0%, #2483FF 100%);
}
.mua-table .mua-th__index,
.mua-table .mua-td__index {
  flex: initial;
  width: 15%;
}
.mua-table .mua-td__index span {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  text-align: center;
  background-image: linear-gradient(90deg, #2483FF 0%, #01D18A 100%);
}
.mua-table .mua-td__sort {
  text-align: left;
}
.mua-table__body {
  width: 100%;
  max-height: 14rem;
  overflow-y: auto;
  box-sizing: border-box;
}
.mua-table__body::-webkit-scrollbar {
  width: 0.25rem;
}
.mua-table__body::-webkit-scrollbar-thumb {
  background-color: #2EDBFF;
  border-radius: 0.25rem;
}
.mua-td .up,
.mua-td .down {
  position: relative;
}
.mua-td .up::before,
.mua-td .down::before {
  content: '';
  display: inline-block;
  width: 10px;
  height: 5px;
  margin-right: 2px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  vertical-align: middle;
}
.mua-td .up::before {
  background-image: url('../assets/red_up.png');
}
.mua-td .down::before {
  background-image: url('../assets/green_down.png');
}
</style>
