<template>
  <div class="mua-button-group">
    <slot></slot>
  </div>
</template>
<script>
  export default {
    name: 'MuaButtonGroup'
  };
</script>
<style scoped>
.mua-button-group {
  display: inline-block;
  vertical-align: middle;
}
.mua-button-group:after, .mua-button-group:before {
  display: table;
  content: "";
}
.mua-button-group:after {
  clear: both;
}
.mua-button-group>.mua-button {
  float: left;
  position: relative;
}
.mua-button-group>.mua-button:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.mua-button-group>.mua-button:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.mua-button-group>.mua-button:not(:last-child) {
  margin-right: -1px;
}
.mua-button-group .mua-button+.mua-button {
  margin-left: 0;
}
</style>
