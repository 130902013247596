import axios from "axios"
// import router from "./router"

// 因为登录注册用的接口和后面的接口地址不一样，所以这里单独处理
let apitest = false
export const loginApi = process.env.NODE_ENV === 'development' || apitest ? 'http://apitest.4008737773.com/' : 'http://api.4008737773.com/'

const https = axios.create({
  baseURL: 'https://dataapi.4008737773.com/',
  timeout: 60000
})

https.interceptors.request.use(
  config => {
    // let user = global.store.get('DATA_USER') || null
    // if (user === null) {
    //   router.push({ path: '/login' })
    //   return
    // }
    // console.log(global.store.get('DATA_USER'))
    return config
  }, error => {
    Promise.reject(error)
  }
)

https.interceptors.response.use(
  response => {
    const res = response.data
    if (
      response.config.url.indexOf('admin/user/read') !== -1 ||
      response.config.url.indexOf('admin/user/setPwd') !== -1
    ) {
      return res
    }

    if (Object.prototype.hasOwnProperty.call(res, 'code')) {
      if (res.code !== 200) {
        return Promise.reject(new Error(res.msg || 'Error'))
      }
    }
    return res
  }, error => {
    return Promise.reject(error)
  }
)

export default https