<template>
  <button
    class="mua-button"
    @click="handleClick"
    :disabled="disabled"
    :class="[
      type ? 'mua-button--' + type : '',
      {
        'is-disabled': disabled
      }
    ]"
  >
    <span v-if="$slots.default"><slot></slot></span>
  </button>
</template>
<script>
  export default {
    name: 'MuaButton',
    props: {
      type: {
        type: String,
        default: 'default'
      },
      disabled: Boolean,
    },

    methods: {
      handleClick(evt) {
        this.$emit('click', evt);
      }
    }
  };
</script>

<style scoped>
.mua-button {
  display: inline-block;
  text-align: center;
  margin: 0;
  padding: 2px 0.5rem;
  font-size: 0.625rem;
  border-radius: 2px;
  line-height: 1;
  white-space: nowrap;
  color: #FFF;
  background-color: transparent;
  border: 1px solid #2EDBFF;
  border-radius: 2px;
  box-sizing: border-box;
  outline: none;
  cursor: pointer;
}
.mua-button--active {
  color: #FFF;
  background: linear-gradient(270deg, #01D18A 0%, #2483FF 100%);
  border: 1px solid #2EDBFF;
}
.mua-button+.mua-button {
  margin-left: 12px;
}
</style>
