import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export const ROUTES = [{
  path: '/',
  name: 'index',
  redirect: '/grail',
  component: () => import('./views/Index.vue'),
  children: [{
    path: 'map',
    name: 'mapMode',
    component: () => import('./views/mapMode/index.vue')
  }, {
    path: 'grail',
    name: 'grailMode',
    component: () => import('./views/grailMode/index.vue')
  }]
},
{
  path: '/login',
  name: 'login',
  component: () => import('./views/Login.vue')
},
{
  path: '/JumpControl',
  name: 'JumpControl',
  component: () => import('./views/JumpControl')
},
{
  path: '/register',
  redirect: '/register/step1',
  name: 'register',
  component: () => import('./views/register/register.vue'),
  children: [{
    path: 'step1',
    name: 'step1',
    component: () => import('./views/register/step/step1.vue')
  }, {
    path: 'step2',
    name: 'step2',
    component: () => import('./views/register/step/step2.vue')
  }, {
    path: 'step3',
    name: 'step3',
    component: () => import('./views/register/step/step3.vue')
  }]
}]

const router = new Router({
  routes: ROUTES
})

// 登录控制
router.beforeEach((to, from, next) => {
  // 如果未匹配到路由, 如果上级也未匹配到路由则跳转首页，如果上级能匹配到则转上级路由
  if (to.matched.length === 0) {
    from.name ? next({ name: from.name }) : next('/')
    return
  }

  // 登录控制
  let user = global.store.get('DATA_USER') || null
  let back = ['/login', '/register', '/register/step1', '/register/step2', '/register/step3', '/JumpControl']
  if (back.includes(to.path) || user !== null) {
    return next()
  } else {
    global.store.remove('DATA_USER')
    return next({ path: '/login' })
  }
})

export default router
