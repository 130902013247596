var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table',{class:[
    'mua-table',
    {
      'mua-table--border': _vm.border
    }
  ],attrs:{"cellspacing":"0","cellpadding":"0","border":"0"}},[_c('div',{class:[
      'mua-table__head',
      {
        'mua-table--background': _vm.background,
        'mua-table--scroll': _vm.scroll
      }
    ]},[_c('table',{attrs:{"cellspacing":"0","cellpadding":"0","border":"0"}},[_c('thead',[_c('tr',{staticClass:"mua-tr"},_vm._l((_vm.head),function(item){return _c('th',{key:item.key,class:[
              'mua-th',
              {
                'mua-th__index': item.key === '$index'
              }
            ],attrs:{"colspan":"1","rowspan":"1"}},[_c('span',[_vm._v(_vm._s(item.title))]),(item.sort)?_vm._t("sort"):_vm._e()],2)}),0)])])]),_c('div',{ref:"mua__tbody",staticClass:"mua-table__body"},[_c('table',{attrs:{"cellspacing":"0","cellpadding":"0","border":"0"}},[_c('tbody',_vm._l((_vm.data),function(item,index){return _c('tr',{key:index,class:['mua-tr', {'mua-tr__hover': _vm.background}],on:{"click":function($event){return _vm.handleClick(item, index)}}},_vm._l((_vm.head),function(td){return _c('td',{key:td.key,class:[
              'mua-td',
              {
                'mua-td__index': td.key === '$index',
                'mua-td__sort': td.sort
              }
            ],attrs:{"colspan":"1","rowspan":"1"}},[(typeof item[td.dataIndex] !== undefined)?_c('span',{class:td.sort ? (+item[td.dataIndex] > 0 ? 'up' : 'down') : ''},[_vm._v(" "+_vm._s(td.sort ? ((Math.abs(+item[td.dataIndex])) + "%") : item[td.dataIndex])+" ")]):_vm._e()])}),0)}),0)])])])}
var staticRenderFns = []

export { render, staticRenderFns }