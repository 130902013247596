<template>
  <label
    :class="[
      'mua-radio',
      { 'is-checked': model === label }
    ]"
  >
    <span
      :class="[
        'mua-radio__input',
        { 'is-checked': model === label }
      ]"
    >
      <span class="mua-radio__inner"></span>
      <input
        ref="radio"
        class="mua-radio__original"
        :value="label"
        type="radio"
        aria-hidden="true"
        v-model="model"
        @focus="focus = true"
        @blur="focus = false"
        @change="handleChange"
        tabindex="-1"
      >
    </span>
    <span class="mua-radio__label">
      <slot></slot>
      <template v-if="!$slots.default">{{label}}</template>
    </span>
  </label>
</template>
<script>
  export default {
    name: 'MuaRadio',
    props: {
      label: String,
      value: {}
    },
    data() {
      return {
        focus: false
      };
    },
    computed: {
      model: {
        get() {
          return this.value
        },
        set(val) {
          this.$emit('input', val)
          this.$refs.radio && (this.$refs.radio.checked = this.model === this.label)
        }
      }
    },
    methods: {
      handleChange() {
        this.$nextTick(() => {
          this.$emit('change', this.model)
        })
      }
    }
  };
</script>

<style scoped>
.mua-radio {
  position: relative;
  display: inline-flex;
  font-size: 0.75rem;
  color: #FFF;
  line-height: 1;
  font-weight: 500;
  white-space: nowrap;
  outline: none;
  cursor: pointer;
}
.mua-radio__input {
  white-space: nowrap;
  cursor: pointer;
  outline: none;
  display: inline-block;
  line-height: 1;
  position: relative;
  vertical-align: middle;
}
.mua-radio__inner {
  border: 1px solid #DCDFE6;
  border-radius: 100%;
  width: 12px;
  height: 12px;
  background-color: transparent;
  position: relative;
  cursor: pointer;
  display: inline-block;
  box-sizing: border-box;
}
.mua-radio__original {
  opacity: 0;
  outline: none;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
}
.mua-radio__label {
  font-size: 0.75rem;
  padding-left: 0.25rem;
}
.mua-radio__input.is-checked .mua-radio__inner {
  border-color: #2483FF;
  background: linear-gradient(270deg, #01D18A 0%, #2483FF 100%);;
}
.mua-radio__input.is-checked .mua-radio__inner::after {
  width: 4px;
  height: 4px;
  border-radius: 100%;
  background-color: #FFF;
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%) scale(1);
  transition: transform .15s ease-in;
}
.mua-radio__input.is-checked+.mua-radio__label {
  color: #2483FF;
}
@keyframes transform {
  from {
    transform: translate(-50%,-50%) scale(0);
  }
  to {
    transform: translate(-50%,-50%) scale(1);
  }
}
</style>
