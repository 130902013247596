<template>
  <div ref="chart" class="mua-chart"></div>
</template>
<script>
import echarts from "echarts"
import {options} from './setting'
export default {
  name: "MuaGrailLine",
  props: {
    yaxis: Array,
    data: Array,
    series: Array,
    optionYaxisUnit: String, // 特殊配置
    hasTwoYaxis: Boolean // 显示左右两条y轴
  },
  data() {
    return {
      chart: null
    };
  },
  watch: {
    data: {
      handler: 'setOption'
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.chart = echarts.init(this.$refs.chart)
      this.chart.setOption(options)
      this.setOption()
    });
  },
  methods: {
    setOption() {
      this.chart.clear()
      const obj = options
      obj.dataset = {
        source: [this.yaxis, ...this.data]
      },
      obj.series = this.series
      if (this.optionYaxisUnit) {
        obj.yAxis[0].axisLabel = {
          formatter: `{value}${this.optionYaxisUnit}`
        } 
      } else {
        obj.yAxis[0].axisLabel = {
          formatter: (value) => {
            let result = value
            if (value > 10000) {
              result = (value / 10000).toFixed(1) + '万'
            }
            return result
          }
        }
      }
      obj.yAxis[1].show = this.hasTwoYaxis
      obj.xAxis.axisLabel = {
        // interval: this.yaxis.length > 15 ? 1 : 'auto'
      }
      this.chart.setOption({...obj})
    }
  }
}
</script>

<style scoped>
.mua-chart {
  width: 100%;
  height: 100%;
}
</style>
