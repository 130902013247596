import Vue from 'vue'
import App from './App.vue'
import router from './router'
import https from './axios'
import translate from './utils/Ch_En_Translate.json'
import {muaLoading, muaButton, muaButtonGroup, muaTable, muaLine, muaGrailLine, muaRadio} from './plugin/index.js'
import {enableBoundaryChecking} from './utils/numberPrecision.js'

Vue.prototype.$https = https
Vue.prototype.$translate = translate

Vue.use(muaLoading)
Vue.use(muaButton)
Vue.use(muaButtonGroup)
Vue.use(muaTable)
Vue.use(muaLine)
Vue.use(muaGrailLine)
Vue.use(muaRadio)

Vue.config.productionTip = false
enableBoundaryChecking(false)

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
