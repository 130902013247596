import loading from './src/loading'

let muaLoading = {}
muaLoading.install = function(Vue) {
  const loadingConstructor = Vue.extend(loading)
  const instance = new loadingConstructor()
  document.body.appendChild(instance.$mount().$el)

  Vue.prototype.$muaLoadingShow = () => {
    instance.visible = true
  }

  Vue.prototype.$muaLoadingHide = () => {
    instance.visible = false
  }
}

export default muaLoading
