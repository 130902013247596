<template>
  <transition name="fade">
    <div class="loading flex-center" v-show="visible">
      <div class="loader-ring">
        <div class="loader-ring-light"></div>
        <div class="loader-ring-track"></div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'muaLoading',
  data() {
    return {
      visible: false
    }
  }
}
</script>

<style scoped>
.loading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1099;
  background-color: rgba(0, 0, 0, 0.6)
}

.loader-ring {
  width: 14vw;
  height: 14vw;
}

.loader-ring-light {
  width: 14vw;
  height: 14vw;
  -moz-border-radius: 14vw;
  -webkit-border-radius: 14vw;
  border-radius: 14vw;
  -moz-box-shadow: 0 4px 0 #00abf2 inset;
  -webkit-box-shadow: 0 4px 0 #00abf2 inset;
  box-shadow: 0 4px 0 #00abf2 inset;
  animation: rotate-360 2s linear infinite;
}

.loader-ring-track {
  position: absolute;
  top: 0;
  left: 0;
  width: 14vw;
  height: 14vw;
  -moz-border-radius: 14vw;
  -webkit-border-radius: 14vw;
  border-radius: 14vw;
  -moz-box-shadow: 0 0 10px 4px rgba(0, 0, 0, 0.3) inset;
  -webkit-box-shadow: 0 0 10px 4px rgba(0, 0, 0, 0.3) inset;
  box-shadow: 0 0 10px 4px rgba(0, 0, 0, 0.3) inset;
}

@keyframes rotate-360 {
  from {
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  to {
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
