<template>
  <div id="app">
    <keep-alive>
      <router-view />
    </keep-alive>
  </div>
</template>

<script>
import { EventBus } from './utils/eventBus'
export default {
  name: 'App',
  mounted() {
    window.onresize = () => {
      if (window.innerWidth >= 1366) {
        EventBus.$emit('resize')
      }
    }
  }
}
</script>

<style lang="less">
/**
* 1vh = 9px
* 1vw = 14.4px
*/
@import './style/normalize.css';
@import './style/mua.less';

html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

h1, h2, h3, h4, h5, h6, p, span, blockquote, br, pre, ol, li, dl, dt, dd {
  user-select: none;
  outline: none;
}
a {
  text-decoration: none;
}
#app {
  width: 100%;
  height: 100%;
}

button {
  border: 0;
  outline: none;
  cursor: pointer;
}

/* 去掉input type=number时的上下箭头 */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type="number"]{
  -moz-appearance: textfield;
}

</style>
