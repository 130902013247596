<template>
  <div ref="chart" class="mua-chart"></div>
</template>
<script>
import echarts from "echarts"
import {option, yesterday, today} from './setting'
export default {
  name: "MuaLine",
  props: {
    data: Array // 是一个数组，如果有两条数据的话就是一个二维数组[[], []]，里面的数据处理了直接拿来用
  },
  data() {
    return {
      chart: null
    };
  },
  watch: {
    data: {
      handler: 'setOption'
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.chart = echarts.init(this.$refs.chart)
      this.chart.setOption(option)
    });
  },
  methods: {
    setOption() {
      let series = []
      if (this.data.length === 2) {
        today.data = this.data[0]
        yesterday.data = this.data[1]
        series = [today, yesterday]
      }
      this.chart.setOption({series, ...option})
    },
  }
};
</script>

<style scoped>
.mua-chart {
  width: 100%;
  height: 100%;
}
</style>
