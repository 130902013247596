/**
 * https://github.com/nefe/number-precision
 */

/**
 * @desc 解决浮动运算问题，避免小数点后产生多位数和计算精度损失。
 * 问题示例：2.3 + 2.4 = 4.699999999999999，1.0 - 0.9 = 0.09999999999999998
 */

/**
 * 把错误的数据转正
 * strip(0.09999999999999998)=0.1
 */
function strip (num, precision = 12) {
  return +parseFloat(num.toPrecision(precision))
}

/**
 * Return digits length of a number
 * @param {*number} num Input number
 */
function digitLength (num) {
  // Get digit length of e
  const eSplit = num.toString().split(/[eE]/)
  const len = (eSplit[0].split('.')[1] || '').length - (+(eSplit[1] || 0))
  return len > 0 ? len : 0
}

/**
 * 把小数转成整数，支持科学计数法。如果是小数则放大成整数
 * @param {*number} num 输入数
 */
function float2Fixed (num) {
  if (num.toString().indexOf('e') === -1) {
    return Number(num.toString().replace('.', ''))
  }
  const dLen = digitLength(num)
  return dLen > 0 ? strip(num * Math.pow(10, dLen)) : num
}

/**
 * 检测数字是否越界，如果越界给出提示
 * @param {*number} num 输入数
 */
function checkBoundary (num) {
  if (_boundaryCheckingState) {
    if (num > Number.MAX_SAFE_INTEGER || num < Number.MIN_SAFE_INTEGER) {
      console.warn(`${num} is beyond boundary when transfer to integer, the results may not be accurate`)
    }
  }
}

/**
 * 精确乘法
 */
function times (num1, num2, ...others) {
  if (others.length > 0) {
    return times(times(num1, num2), others[0], ...others.slice(1))
  }
  const num1Changed = float2Fixed(num1)
  const num2Changed = float2Fixed(num2)
  const baseNum = digitLength(num1) + digitLength(num2)
  const leftValue = num1Changed * num2Changed

  checkBoundary(leftValue)

  return leftValue / Math.pow(10, baseNum)
}

/**
 * 精确加法
 */
function plus (num1, num2, ...others) {
  if (others.length > 0) {
    return plus(plus(num1, num2), others[0], ...others.slice(1))
  }
  const baseNum = Math.pow(10, Math.max(digitLength(num1), digitLength(num2)))
  return (times(num1, baseNum) + times(num2, baseNum)) / baseNum
}

/**
 * 精确减法
 */
function minus (num1, num2, ...others) {
  if (others.length > 0) {
    return minus(minus(num1, num2), others[0], ...others.slice(1))
  }
  const baseNum = Math.pow(10, Math.max(digitLength(num1), digitLength(num2)))
  return (times(num1, baseNum) - times(num2, baseNum)) / baseNum
}

/**
 * 精确除法
 */
function divide (num1, num2, ...others) {
  if (others.length > 0) {
    return divide(divide(num1, num2), others[0], ...others.slice(1))
  }
  const num1Changed = float2Fixed(num1)
  const num2Changed = float2Fixed(num2)
  checkBoundary(num1Changed)
  checkBoundary(num2Changed)
  return times((num1Changed / num2Changed), Math.pow(10, digitLength(num2) - digitLength(num1)))
}

/**
 * 四舍五入 isForce 是否强制保留小数位
 */
function round (num, ratio, isForce = false) {
  const base = Math.pow(10, ratio)
  const rNum = divide(Math.round(times(num, base)), base)
  if (isForce) {
    let numStr = rNum.toString()
    if (numStr.indexOf('.') < 0) {
      numStr += '.'
    }
    let decIndexof = numStr.indexOf('.')
    while (numStr.length <= decIndexof + 2) {
      numStr += '0'
    }
    return numStr
  } else {
    return rNum
  }
}

let _boundaryCheckingState = true
/**
 * 是否进行边界检查，默认开启
 * @param flag 标记开关，true 为开启，false 为关闭，默认为 true
 */
function enableBoundaryChecking (flag = true) {
  _boundaryCheckingState = flag
}

/**
 * 数字转大写
 * @param num 传入数字
 */
function toChineseNum (num) {
  let changeNum = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九']
  let unit = ['', '十', '百', '千', '万']
  num = parseInt(num)
  let getWan = (temp) => {
    let strArr = temp.toString().split('').reverse()
    let newNum = ''
    for (var i = 0; i < strArr.length; i++) {
      let c = changeNum[strArr[i]] + (strArr[i] === 0 ? unit[0] : unit[i])
      let b = i > 0 && strArr[i] === 0 && strArr[i - 1] === 0 ? '' : c
      let a = i === 0 && strArr[i] === 0 ? '' : b
      newNum = a + newNum
    }
    return newNum
  }
  let overWan = Math.floor(num / 10000)
  let noWan = num % 10000
  if (noWan.toString().length < 4) noWan = '0' + noWan
  return overWan ? getWan(overWan) + '万' + getWan(noWan) : getWan(num)
}

/**
 * 转千分位
 * @param num 
 */
function numFormat(num) {
  let c = (num.toString().indexOf ('.') !== -1) ? num.toLocaleString() : num.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
  return c
}

export { strip, plus, minus, times, divide, round, digitLength, float2Fixed, enableBoundaryChecking, toChineseNum, numFormat }
export default { strip, plus, minus, times, divide, round, digitLength, float2Fixed, enableBoundaryChecking, toChineseNum, numFormat }
