export const option = {
  tooltip: {
    trigger: 'axis'
  },
  grid: {
    left: '10%',
    bottom: '15%',
    top: '10%',
    right: '2%'
  },
  xAxis: {
    type: 'category',
    boundaryGap: false,
    axisLine: {
      lineStyle: {
        color: '#fff'
      }
    },
    axisTick: {
      show: false
    },
    axisPointer: {
      show: true,
      label: {
        show: false
      },
      lineStyle: {
        color: '#2EDBFF'
      }
    },
    data: ['00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00']
  },
  yAxis: {
    type: 'value',
    axisLine: {
      lineStyle: {
        color: '#fff'
      }
    },
    axisTick: {
      inside: true
    },
    splitLine: {
      lineStyle: {
        color: '#333',
        type: 'dashed'
      }
    }
  }
}

export const yesterday = {
  name: '昨日',
  data: [],
  type: 'line',
  lineStyle: {
    color: '#fff',
    type: 'dashed'
  },
  itemStyle: {
    color: 'fff'
  }
}

export const today = {
  name: '今日',
  data: [],
  type: 'line',
  areaStyle: {},
  color: '#2EDBFF'
}
